



















































































































































































































































import auth from '@/mixins/auth';
import { Shipment } from '@/models/shipment';
import dateFormat from '@/utils/dateFormat';
import { PropType } from 'vue';
export default auth.extend({
  props: {
    filterShipment: {
      type: [] as PropType<Shipment[]>
    }
  },
  data() {
    return {
      adminId: 1
    };
  },
  methods: {
    dateFormat,

    openDialog(shipment: Shipment) {
      this.$emit('openDialog', shipment, true);
    },

    selectedShipment(shipment: Shipment) {
      this.$emit('selectedShipment', shipment);
    },

    openDueDateFormDialog(shipment: Shipment) {
      this.$emit('openDueDateFormDialog', shipment);
    },

    openPackageInformation(shipment: Shipment) {
      this.$emit('openPackageInformation', shipment);
    },

    updatePackageStatus(shipment: Shipment) {
      this.$emit('updatePackageStatus', shipment);
    }
  }
});
